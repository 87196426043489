var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',{staticClass:"ma-0 px-0"},[_c('v-col',{attrs:{"cols":"12","md":"3","lg":"3"}},[_c('v-card',{attrs:{"outlined":""}},[_c('v-card-title',{staticClass:"py-3 text-wrap"},[_vm._v(" "+_vm._s(_vm.$ml.get("manage_permissions"))+" ")]),_c('v-divider'),_c('v-list',{attrs:{"nav":""}},[_c('v-list-item',{staticClass:"pl-3"},[_c('v-list-item-avatar',{attrs:{"tile":"","size":"45"}},[_c('Logo',{attrs:{"product":"conecta_control","width":"45","noMargins":""}})],1),_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"subtitle-1"},[_vm._v(" Conecta Control ")])],1)],1)],1)],1)],1),_c('v-col',{attrs:{"cols":"12","md":"9","lg":"9"}},[_c('v-card',{attrs:{"outlined":""}},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.allAdmins,"sort-by":['email'],"loading":_vm.allLoadings,"items-per-page":_vm.itemsPerPage,"footer-props":{ itemsPerPageOptions: _vm.itemsOptions },"height":_vm.tableHeight,"fixed-header":"","mobile-breakpoint":"0"},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('TableHeader',{attrs:{"id":"admin_functions_header","action":"openAddDialog","custom-left":"","action-text":"add_admin","action-icon":"mdi-account-plus","hide-divider":""},on:{"openAddDialog":function($event){_vm.showAddAdmins = true}}},[_c('template',{slot:"left"},[_c('v-card-actions',{staticClass:"px-0"},[_c('div',{staticClass:"my-auto",staticStyle:{"max-width":"45px"}},[_c('Logo',{attrs:{"product":"conecta_control","heigth":"45","noMargins":""}})],1),_c('v-card-title',[_vm._v("Conecta Control")])],1)],1)],2)]},proxy:true},{key:"header.username",fn:function(ref){
var header = ref.header;
return [_vm._v(" "+_vm._s(_vm.$ml.get(header.key))+" ")]}},{key:"header.email",fn:function(ref){
var header = ref.header;
return [_vm._v(" "+_vm._s(_vm.$ml.get(header.key))+" ")]}},{key:"item.username",fn:function(ref){
var item = ref.item;
return [_c('v-row',{staticClass:"ma-0"},[_c('v-col',{attrs:{"cols":"1"}},[_c('v-avatar',{attrs:{"size":"25"}},[_c('img',{attrs:{"src":item.photo || _vm.userDefaultImage,"alt":item.username}})])],1),_c('v-col',[_c('span',{staticClass:"blue--text text--darken-2 link ml-2",on:{"click":function($event){return _vm.$router.push({ name: 'User', params: { id: item.key } })}}},[_vm._v(" "+_vm._s(item.name || item.username)+" ")])])],1)]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"disabled":item.is_admin,"right":"","text":"","small":"","color":"accent"},on:{"click":function($event){return _vm.openRemoveAdminDialog(item)}}},[_vm._v(" "+_vm._s(_vm.$ml.get("remove"))+" ")])]}}],null,true)})],1)],1)],1),_c('HeaderDialog',{attrs:{"show":_vm.showAddAdmins,"id":"add_admin","title":_vm.$ml.get('add_admin'),"actionText":_vm.$ml.get('add'),"disabledAction":_vm.newAdmin === '' || _vm.newAdmin === null,"closeText":"cancel","width":"600","loading":_vm.allLoadings},on:{"close":function($event){_vm.showAddAdmins = false},"action":_vm.addAdmin},scopedSlots:_vm._u([{key:"body",fn:function(){return [_c('p',[_vm._v(" "+_vm._s(_vm.$ml.get("add_admin_main_text"))+" ")]),_c('p',{staticClass:"mb-4"},[_vm._v(" "+_vm._s(_vm.$ml.get("add_admin_helper_text"))+" ")]),_c('v-autocomplete',{staticClass:"mt-4",attrs:{"items":_vm.nonAdmins,"item-text":"email","item-value":"key","label":_vm.$ml.get('email')},model:{value:(_vm.newAdmin),callback:function ($$v) {_vm.newAdmin=$$v},expression:"newAdmin"}})]},proxy:true}])}),_c('HeaderDialog',{attrs:{"show":_vm.showRemoveAdmin,"id":"remove_admin","title":_vm.$ml.get('remove_admin'),"subtitle":_vm.selectedUserEmail,"actionText":_vm.$ml.get('confirm'),"closeText":"cancel","width":"600","loading":_vm.allLoadings},on:{"close":function($event){_vm.showRemoveAdmin = false},"action":_vm.removeAdmin},scopedSlots:_vm._u([{key:"body",fn:function(){return [_c('p',[_vm._v(" "+_vm._s(_vm.$ml.get("remove_admin_main_text"))+" ")])]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }