<template>
  <v-container fluid>
    <v-row class="ma-0 px-0">
      <!-- MENU ESQUERDO -->
      <v-col cols="12" md="3" lg="3">
        <v-card outlined>
          <v-card-title class="py-3 text-wrap">
            {{ $ml.get("manage_permissions") }}
          </v-card-title>

          <v-divider />

          <v-list nav>
            <v-list-item class="pl-3">
              <v-list-item-avatar tile size="45">
                <Logo product="conecta_control" width="45" noMargins />
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title class="subtitle-1">
                  Conecta Control
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card>
      </v-col>

      <!-- CARD DE CONTEÚDO -->
      <v-col cols="12" md="9" lg="9">
        <v-card outlined>
          <v-data-table
            :headers="headers"
            :items="allAdmins"
            :sort-by="['email']"
            :loading="allLoadings"
            :items-per-page="itemsPerPage"
            :footer-props="{ itemsPerPageOptions: itemsOptions }"
            :height="tableHeight"
            fixed-header
            mobile-breakpoint="0"
          >
            <template v-slot:top>
              <TableHeader
                id="admin_functions_header"
                action="openAddDialog"
                custom-left
                action-text="add_admin"
                action-icon="mdi-account-plus"
                hide-divider
                @openAddDialog="showAddAdmins = true"
              >
                <template slot="left">
                  <v-card-actions class="px-0">
                    <div style="max-width: 45px" class="my-auto">
                      <Logo product="conecta_control" heigth="45" noMargins />
                    </div>
                    <v-card-title>Conecta Control</v-card-title>
                  </v-card-actions>
                </template>
              </TableHeader>
            </template>
            <!-- HEADERS - INÍCIO -->
            <template v-slot:[`header.username`]="{ header }">
              {{ $ml.get(header.key) }}
            </template>
            <template v-slot:[`header.email`]="{ header }">
              {{ $ml.get(header.key) }}
            </template>
            <!-- HEADERS - FIM -->
            <!-- BODY - INÍCIO -->
            <template v-slot:[`item.username`]="{ item }">
              <v-row class="ma-0">
                <v-col cols="1">
                  <v-avatar size="25">
                    <img
                      :src="item.photo || userDefaultImage"
                      :alt="item.username"
                    />
                  </v-avatar>
                </v-col>
                <v-col>
                  <span
                    class="blue--text text--darken-2 link ml-2"
                    @click="
                      $router.push({ name: 'User', params: { id: item.key } })
                    "
                  >
                    {{ item.name || item.username }}
                  </span>
                </v-col>
              </v-row>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <v-btn
                :disabled="item.is_admin"
                right
                text
                small
                color="accent"
                @click="openRemoveAdminDialog(item)"
              >
                {{ $ml.get("remove") }}
              </v-btn>
            </template>
            <!-- BODY - FIM -->
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
    <!-- </v-card> -->

    <!-- DIALOG: ADICIONAR ADMINISTRADOR -->
    <HeaderDialog
      :show="showAddAdmins"
      id="add_admin"
      :title="$ml.get('add_admin')"
      :actionText="$ml.get('add')"
      :disabledAction="newAdmin === '' || newAdmin === null"
      @close="showAddAdmins = false"
      @action="addAdmin"
      closeText="cancel"
      width="600"
      :loading="allLoadings"
    >
      <template v-slot:body>
        <p>
          {{ $ml.get("add_admin_main_text") }}
        </p>

        <p class="mb-4">
          {{ $ml.get("add_admin_helper_text") }}
        </p>
        <v-autocomplete
          class="mt-4"
          :items="nonAdmins"
          item-text="email"
          item-value="key"
          v-model="newAdmin"
          :label="$ml.get('email')"
        ></v-autocomplete>
      </template>
    </HeaderDialog>
    <!-- DIALOG: ADICIONAR ADMINISTRADOR - FIM -->

    <!-- DIALOG: REMOVER ADMINISTRADOR -->
    <HeaderDialog
      :show="showRemoveAdmin"
      @close="showRemoveAdmin = false"
      id="remove_admin"
      :title="$ml.get('remove_admin')"
      :subtitle="selectedUserEmail"
      :actionText="$ml.get('confirm')"
      @action="removeAdmin"
      closeText="cancel"
      width="600"
      :loading="allLoadings"
    >
      <template v-slot:body>
        <p>
          {{ $ml.get("remove_admin_main_text") }}
        </p>
      </template>
    </HeaderDialog>
  </v-container>
</template>

<script>
import HeaderDialog from "@/components/base/HeaderDialog";
import { mapActions, mapGetters, mapMutations } from "vuex";
import { paging, tableHeight } from "@/helpers/utils";

export default {
  name: "AdminFunctions",
  components: { HeaderDialog },
  data() {
    return {
      headers: [
        {
          key: "user",
          align: "start",
          value: "username",
          width: 300,
        },
        { key: "email", value: "email" },
        { text: "", value: "actions", sortable: false },
      ],
      loading: false,
      showAddAdmins: false,
      showRemoveAdmin: false,
      newAdmin: null,
      selectedUser: {},
      itemsPerPage: paging.perPage,
      itemsOptions: paging.All,
      tableHeight,
    };
  },
  computed: {
    ...mapGetters([
      "token",
      "userDefaultImage",
      "users",
      "admins",
      "loadingUsers",
      "loadingSync",
    ]),

    allLoadings() {
      return this.loading || this.loadingSync || this.loadingUsers;
    },

    allAdmins() {
      return this.admins;
    },
    adminsListByEmail() {
      return this.admins.map((admin) => admin.email);
    },
    nonAdmins() {
      return this.users.filter(
        (user) => !this.adminsListByEmail.includes(user.email)
      );
    },
    newAdminInfo() {
      return this.users.find((user) => user.key === this.newAdmin);
    },
    selectedUserEmail() {
      return this.selectedUser.email;
    },
  },
  methods: {
    ...mapActions(["setSnackbarCustomMessage"]),
    ...mapMutations(["setNewAdmin", "removeAdminFromStore"]),

    addAdmin() {
      if (this.newAdmin) {
        this.loading = true;
        const url = `/admins/${this.newAdmin}`;
        this.$axios
          .post(url)
          .then(({ data }) => {
            this.setSnackbarCustomMessage({
              message: `${this.$ml.get("success_add_admin")} ${
                this.newAdminInfo.email
              }`,
              color: "success",
            });
            this.setNewAdmin(data);
          })
          .catch((error) => {
            this.setSnackbarCustomMessage({
              message: `${this.$ml.get("error_add_admin")}`,
              color: "error",
            });
            console.error("addAdmin(): ", error);
          })
          .finally(() => {
            this.loading = false;
            this.showAddAdmins = false;
            this.newAdmin = null;
          });
      }
    },
    openRemoveAdminDialog(user) {
      this.selectedUser = Object.assign(user, {});
      this.showRemoveAdmin = true;
    },

    removeAdmin() {
      this.loading = true;

      const { key } = this.selectedUser;
      const url = `/admins/${key}`;

      this.$axios
        .delete(url)
        .then(() => {
          this.removeAdminFromStore(key);
          this.setSnackbarCustomMessage({
            message: `${this.$ml.get("success_remove_admin")} ${
              this.selectedUser.email
            }`,
            color: "success",
          });
        })
        .catch((error) => {
          this.setSnackbarCustomMessage({
            message: `${this.$ml.get("error_remove_admin")}`,
            color: "error",
          });
          console.error("removeAdmin(): ", error);
        })
        .finally(() => {
          this.showRemoveAdmin = false;
          this.loading = false;
          this.showAddAdmins = false;
        });
    },
  },
};
</script>
